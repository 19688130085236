import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaqsDataStoreFlagService {
  private extraData: string;

  setExtraData(data: string) {
    this.extraData = data;
  }

  getExtraData(): string {
    return this.extraData;
  }
}
