//Angular
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
//Services
import { MacroService } from '../../../services/macro.service';
import { BnaService } from '../../../services/bna.service';
import { BancoCiudadService } from '../../../services/banco-ciudad.service';
//Other
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.sass']
})
export class ExternalComponent implements OnInit {
  env: any;
  token: String;
  error: string | null  = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private macroService: MacroService,
    private bnaService: BnaService,
    private bancoCiudadService: BancoCiudadService,
    ) { }

  ngOnInit() {
    this.env = environment;
    this.token = this.route.snapshot.params['token'];

    if (this.token == null){
      this.router.navigate(['/']);
    } else {
      switch(this.env.name){
        case 'Macro':
          this.macroService.getToken(this.token);
        break;
        case 'EspecielesBNA':
        case 'BancoNacion':
        case 'MiMoto':
          this.bnaService.getHash(this.route.queryParams).subscribe(
            response => {},
            error => {
              this.error = error}
          );
        break;
        case 'EspecialesBancoCiudad':
          this.bancoCiudadService.getHash(this.route.queryParams);
        break;
        default:
          this.router.navigate(['/']);
        break;
      }

    }

  }

}
