import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errorData: any;

  constructor() { }


  setError(error: any) {
    this.errorData = error;
  }

  getError() {
    return this.errorData;
  }

  clearError() {
    this.errorData = null;
  }
}
