//Angular
import { Injectable } from '@angular/core';
//Models
import { Customer } from "../models/models.model";
//Services
import { HttpService } from "./http.service";
import { PointsService } from './points.service';
import { RelationService } from './model.service';
import { LocalStorage } from './local_storage.service';
import { ProfileService } from './account/profile.service';
//Other
import { Subject } from 'rxjs';

@Injectable()

export class CustomerService {
  customerUpdate = new Subject<boolean>();
  customer: Customer;

  constructor(protected http: HttpService,
              protected localstorage: LocalStorage,
              protected pointsService: PointsService,
              protected profileService: ProfileService,
              protected relationService: RelationService,
              ){this.customer = new Customer();}

  setCustomer(customer: Customer) {
    if (!this.customer) {this.customer = new Customer()}
    this.customer.setCustomer(customer);
    this.localstorage.set('name', this.customer.getFullName());
    this.setCustomerPoints(this.customer.points);
    this.setCustomerPointsMoney(this.customer.points_money);
    this.customerUpdate.next(true);
  }
  
  name() {
    return this.localstorage.get('name');
  }
  
  getCustomer(): Customer {
    return this.customer;
  }

  destroyCustomer() {
    this.localstorage.remove('name');
    delete this.customer;
  }

  init() {
    return this.http.get('users/me');
  }

  setPoints(points) {
    this.pointsService.setPoints(points);
  }

  getPoints() {
    return this.pointsService.getPoints();
  }

  getCustomerPoints(): number {
    let customerPoints = this.localstorage.get('customer_points') as string;
    return Number(customerPoints);
  }

  setCustomerPoints(points: number): void {
    this.localstorage.set('customer_points', points);
  }

  getCustomerPointsMoney(): number {
    let customerPointsMoney = this.localstorage.get('customer_points_money') as string;
    return Number(customerPointsMoney);
  }

  setCustomerPointsMoney(pointsMoney: number): void {
    this.localstorage.set('customer_points_money', pointsMoney);
  }

}
