import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CountdownService {
  private _loadingCountdown = new BehaviorSubject<boolean>(true);

  get loadingCountdown$() : Observable<boolean> {
    return this._loadingCountdown.asObservable();
  }

  set loadingCountdown(value : boolean) {
    this._loadingCountdown.next(value);
  }
}
