// Imports
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { SkeletonLoaderComponent } from "../app/layout/skeleton-loader/skeleton-loader.component";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  CountdownComponent
} from "../app/layout/countdown/countdown.component";


@NgModule({
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule,
  ],
  exports: [
    CommonModule,
    NgxSkeletonLoaderModule,
    SkeletonLoaderComponent,
    CountdownComponent
  ],
  declarations: [
    SkeletonLoaderComponent,
    CountdownComponent
  ]
})

export class SkeletonModule { }
