import {Component, Input, OnInit} from '@angular/core';
import {UrlService} from "../../../../../services/url.service";
import { MktService } from './../../../../new-core/core/services/mkt.service';
import { GtmBuilderService } from "../../../../../services/gtm_builder.service";
import { environment } from "../../../../../environments/environment";
import { Router } from '@angular/router';
import { FaqsDataStoreFlagService } from 'services/faqs-data-store-flag.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {
  private readonly env = environment
  @Input('item') item: any;

  constructor(
    private urlService: UrlService,
    private _mktService: MktService,
    private _gtmBuilderService: GtmBuilderService,
    private router: Router,
    private faqsDataStoreFlag: FaqsDataStoreFlagService,
    ) { }

  ngOnInit() {
    // console.log(this.item);
  }

  hasTitle(): boolean {
    return (this.item.title !== undefined)  && (this.item.title.length > 0);
  }

  navigate(link) {
    if (this.env.name === 'Macro') this._gtmBuilderService.builderSelectPromotion(this.item)
    this._mktService.collect('CLICK-IN-OFFER', link)
    const containsBnaMovilSegment = this.item?.link.includes('Preguntas-frecuentes-bnamovil');
    if (containsBnaMovilSegment) {
      this.faqsDataStoreFlag.setExtraData('MiMoto');
      this.router.navigate(['/faqs']);
      return;
    } 
    this.urlService.navigate(this.urlService.linkSelector(this.item));
  }
}
