import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import { STORE_NAME } from '../../core/constants/store.constants';
import { environment } from '../../../environments/environment';
import { interval, Subject, Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { CountdownService } from './countdown.service';
import { takeUntil, tap } from 'rxjs/operators';

type Units = 'days' | 'hours' | 'minutes' | 'seconds';

@Component({
  selector: 'ui-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CountdownComponent implements OnInit, OnDestroy {
  // A la fecha final, hay que agregarle 1 min, porque el ultimo min se verifica por segundos y desaparecera 1 min antes.
  @Input() finishDate: string; // pasar fecha 2024-10-17T00:01:00 en este formato
  readonly STORE_NAME = STORE_NAME;
  readonly storeName = environment.name;
  private _targetDate: DateTime; // Fecha límite
  private _countdownSubscription!: Subscription;
  private _unsubscribeAll$ = new Subject<void>();
  timeRemaining: Record<Units, number> = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
  isFinished: boolean = true; // oculta cuando llega a fecha limite
  isLoading: boolean = true; // oculta mientras se este cargando

  constructor(
    private _countdownService: CountdownService
  ) {}

  ngOnInit(): void {
    this._countdownService.loadingCountdown$
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe((isLoading) => (this.isLoading = isLoading));

    this._targetDate = DateTime.fromISO(this.finishDate, {
      zone: 'America/Argentina/Buenos_Aires'
    });

    this.startCountdown();
  }

  startCountdown(): void {
    // Emitir un valor cada segundo 1000 ms
    this._countdownSubscription = interval(1000).subscribe(() => {
      this.calculateTimeRemaining();
    });
  }

  calculateTimeRemaining(): void {
    // Calcular la diferencia entre la fecha actual y la fecha límite
    const today: DateTime = DateTime.now().setZone(
      'America/Argentina/Buenos_Aires'
    );
    const timeDifference = this._targetDate
      .diff(today, ['days', 'hours', 'minutes', 'seconds'])
      .toObject();
    // Actualizar el tiempo restante si hay diferencia
    if (
      timeDifference.days! >= 0 &&
      timeDifference.hours! >= 0 &&
      Math.floor(timeDifference.minutes) > 0
    ) {
      this.timeRemaining = {
        days: Math.floor(timeDifference.days),
        hours: Math.floor(timeDifference.hours),
        minutes: Math.floor(timeDifference.minutes),
        seconds: Math.floor(timeDifference.seconds)
      };
      this.isFinished = false;
    } else {
      // Cuando la cuenta regresiva llega a 0
      this.timeRemaining = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
      this.isFinished = true;
    }
  }

  ngOnDestroy(): void {
    if(this._countdownSubscription) this._countdownSubscription.unsubscribe()
    this._unsubscribeAll$.next(null);
    this._unsubscribeAll$.complete();
  }
}
