import { Injectable, NgZone, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private timeoutId: number | null = null;
  private readonly timeout: number = 1800000; // 30 min
  env: any;

  constructor(
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: any // Inyecta PLATFORM_ID
  ) {
    this.env = environment;
    if (isPlatformBrowser(this.platformId)) { // Verifica si está en el navegador
      this.startWatching();
      this.resetTimer();
    }
  }

  startWatching() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('mousemove', () => this.resetTimer());
      window.addEventListener('keypress', () => this.resetTimer());
      window.addEventListener('click', () => this.resetTimer());
    });
  }

  resetTimer() {
    this.ngZone.run(() => {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => this.logout(), this.timeout);
    });
  }

  logout() {
    if (this.authenticationService.isLoggedIn() && this.env.name === "BancoNacion") {
      alert('Sesión expirada por inactividad.');
      this.authenticationService.logout();
    }
  }
}
