//Angular
import { Injectable } from '@angular/core';
//Models
import { Product } from '../models/models.model';
import { CheckoutItem } from '../models/models.model';
//Services
import { LocalStorage } from './local_storage.service';
//Other
import { BehaviorSubject, Subject } from 'rxjs';
import { CheckoutCart } from '../models/models.model';

@Injectable()

export class PointsService {
  private selectedShippingSubject = new BehaviorSubject<{ fullPesos: boolean, costPoints: number, moneyPoints: number, leftCostShipping: number, currentStep: number }>({ fullPesos: true, costPoints: null, moneyPoints: null, leftCostShipping: null, currentStep: 0 });
  selectedShipping$ = this.selectedShippingSubject.asObservable();

  status = new Subject<boolean>();
  cart = new CheckoutCart;

  constructor(private localstorage: LocalStorage) { }

  setSelectedShipping(fullPesos: boolean, costPoints?: number, moneyPoints?: number, leftCostShipping?: number) {
    const { currentStep } = this.selectedShippingSubject.value;
    this.selectedShippingSubject.next({ fullPesos, costPoints, moneyPoints, leftCostShipping, currentStep });
  }

  setCurrentStepShipping(currentStep) {
    if (currentStep === 0) {
      this.selectedShippingSubject.next({ fullPesos: true, costPoints: null, moneyPoints: null, leftCostShipping: null, currentStep: 0 })
      return;
    }
    this.selectedShippingSubject.next({...this.selectedShippingSubject.value, currentStep });
  }

  getPoints():number {
    return Number(this.localstorage.get('points'));
  }

  setPoints( newPoints: number ) {

    const points = newPoints;

    this.localstorage.set('points', points);

    this.status.next(true);
  }

  updatePoints( newPoints: number ) {

    const points = +this.getPoints() + newPoints;

    this.setPoints(points);
  }

  updateLocalStoragePoints(newPoints: number) {
    const points = +this.getPoints();
    const totalPoints = (points - newPoints);
    if (newPoints) this.setPoints(totalPoints > 0 ? totalPoints : 0);
  }

  destroyPoints() {

    this.localstorage.remove('points');

  }

  pointsEquivalence( product: Product ) {

    return Math.ceil( ( product.points_equivalence ? product.points_equivalence : 0 ) );

  }

  creditValue( product: Product, points: number ) {

    if ( product.points_equivalence < 1 ) {

      return Math.ceil( ( product.points_equivalence ? product.points_equivalence : 0 ) * points );

    } else {

      return Math.floor( ( product.points_equivalence ? product.points_equivalence : 0 ) * points );
    }
  }

  subTotal( checkoutItem: CheckoutItem[] ) {

    let total: number = 0;

    checkoutItem.forEach(

      element => { total += element.points }

    );

    return total;
  }

  inCreditAmount( item ) {

    if ( item ) {

      const limit = item.total - this.creditValue( item.product, item.points ) * item.quantity;

      if ( limit <= 0 ) return 0;

      return Math.ceil(limit);

    }

    return 0;
  }

}
