import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ComponentLanding, Landing} from "../../../models/models.model";
import {Cols1Pics1V1Component} from "./components/cols1-pics1-v1/cols1-pics1-v1.component";
import {Cols2GenericV1Component} from "./components/cols2-generic-v1/cols2-generic-v1.component";
import {Cols4BrandsV1Component} from "./components/cols4-brands-v1/cols4-brands-v1.component";
import {Cols4VariantsV1Component} from "./components/cols4-variants-v1/cols4-variants-v1.component";
import {HeroComponent} from "./components/hero/hero.component";
import {HeroBannerComponent} from "./components/hero-banner/hero-banner.component";
import {Cols1Pics2V1Component} from "./components/cols1-pics2-v1/cols1-pics2-v1.component";
import {Cols2BrandV2Component} from "./components/cols2-brand-v2/cols2-brand-v2.component";
import {Cols4LinksV1Component} from "./components/cols4-links-v1/cols4-links-v1.component";
import {Cols4PostsV1Component} from "./components/cols4-posts-v1/cols4-posts-v1.component";
import {Cols5GenericV1Component} from "./components/cols5-generic-v1/cols5-generic-v1.component";
import {Cols2Pic3V1Component} from "./components/cols2-pic3-v1/cols2-pic3-v1.component";
import {EditorComponent} from "./components/editor/editor.component";
import {SliderComponent} from "./components/slider/slider.component";
import {DefaultComponent} from "./components/default/default.component";
import {SubscriptionComponent} from "./components/subscription/subscription.component";
import {MiniLogoComponent} from "./components/mini-logo/mini-logo.component";
import { CountdownService } from "../../layout/countdown/countdown.service";


@Component({
  selector: 'app-home',
  templateUrl: './base.component.html'
})

export class BaseComponent implements OnInit {

  componentsFactory: any[] = [
    Cols1Pics1V1Component,
    Cols2GenericV1Component,
    Cols4BrandsV1Component,
    Cols4VariantsV1Component,
    HeroComponent,
    HeroBannerComponent,
    Cols1Pics2V1Component,
    Cols2BrandV2Component,
    Cols4LinksV1Component,
    Cols4PostsV1Component,
    Cols5GenericV1Component,
    Cols2Pic3V1Component,
    SliderComponent,
    EditorComponent,
    SubscriptionComponent,
    MiniLogoComponent,
    DefaultComponent // Default should be always in the last position
  ];

  landing: Landing;
  components = [];
  @ViewChild('componentsViewer', { read: ViewContainerRef, static: true }) componentsViewer: ViewContainerRef;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public countdownService: CountdownService
  ) { }

  ngOnInit() {
  }

  addComponent(component: ComponentLanding) {
    this.countdownService.loadingCountdown = false
    for (const factory of this.componentsFactory) {
      if (factory.acceptsComponent(component)) {
        const f = this.componentFactoryResolver.resolveComponentFactory(factory);
        const comp = this.componentsViewer.createComponent(f).instance;
        comp['component'] = component;
        return;
      }
    }
  }
}
