// Angular
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
// Services
import { UrlService } from './url.service';
import { HttpService } from './http.service';
import { PointsService } from './points.service';
import { CustomerService } from './customer.service';
import { RelationService } from './model.service';
import { LocalStorage } from './local_storage.service';
import { ProfileService } from './account/profile.service';
import { AuthenticationService } from './authentication.service';
// Models
import { CheckoutCart } from '../models/models.model';
import { BnaUser } from '../models/models.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()

export class BnaService extends CustomerService {
    checkoutCart: CheckoutCart;
    userBna: BnaUser;
    priceLimit: any;
    error: string | null = null;

    constructor(http: HttpService,
                localstorage: LocalStorage,
                pointsService: PointsService,
                profileService: ProfileService,
                relationService: RelationService,
                private dialog: MatDialog,
                private urlService: UrlService,
                private router: Router,
                private authenticationService: AuthenticationService) {
                    super(http, localstorage, pointsService, profileService, relationService)
                    this.userBna = new BnaUser();
                }

    getHash(hash): Observable<any> {

      let parameters = {};

      hash.subscribe(params => { parameters = params });

      return this.http.get('bna/hash_validator/validate', parameters).pipe(
        map((response: any) => {
            this.userBna.setBnaUser(response);
            this.setBnaCustomer();
            return response;
          }),
          catchError(error => {
            console.error('Error in getHash:', error?.error?.error);
            return throwError(error?.error?.error);
          })
      );
    }

    setBnaCustomer() {
        this.destroyBnaCustomer();
        this.localstorage.set('sucursal', this.userBna.getSucursal());
        this.localstorage.set('name', this.userBna.getFullName());
        this.localstorage.set('cuil', this.userBna.getCuil());
        this.localstorage.set('email', this.userBna.getEmail());
        this.localstorage.set('fecha', this.userBna.getFecha());
        this.localstorage.set('idSolicitud', this.userBna.getIdSolicitud());
        this.localstorage.set('monto', this.userBna.getMaxAmount());
        this.localstorage.set('perfil', this.userBna.getPerfil());
        this.localstorage.set('programa', this.userBna.getPrograma());
        this.customerUpdate.next(true);
        this.priceLimit = '0..' + String((this.localstorage.get('monto') ? this.localstorage.get('monto') : ''));
        this.router.navigate(['/catalog/concesionarios-por-provincias']);
    }

    getBnaCustomer(): BnaUser {
        return this.userBna;
    }

    getMaxAmount() {
        return  this.localstorage.get('monto') ? this.localstorage.get('monto') : '';
    }

    destroyBnaCustomer() {
        this.localstorage.remove('name');
        this.localstorage.remove('email');
        this.localstorage.remove('cuil');
        this.localstorage.remove('sucursal');
        this.localstorage.remove('monto');
        this.localstorage.remove('fecha');
        this.localstorage.remove('idSolicitud');
        this.localstorage.remove('perfil');
        this.localstorage.remove('programa');
    }

    bnaLogout() {
        this.destroyBnaCustomer();
        delete this.customer;
        delete this.userBna;
        this.authenticationService.logout();
    }

    redirectToBna() {
        this.destroyBnaCustomer();
        this.urlService.navigate("https://bna.com.ar/ABM/AltaProducto/BNAMovil");
    }

}
