//Angular
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit, Renderer2, ElementRef } from '@angular/core';
//Services
import { PointsService } from '../../../services/points.service';
import { PriceService } from '../../../services/price.service';
import { CheckoutService } from '../../../services/checkout.service';
import { RelationService } from '../../../services/model.service';
import { InstallmentService } from '../../../services/views/installment.service';
//Models
import { CheckoutCart } from '../../../models/models.model';
//Other
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../../services/authentication.service';
import * as lodash from 'lodash-es';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})
export class CartComponent implements OnInit, OnDestroy {
  installmentsError: boolean = false;
  loaded = false;
  points: any;
  subTotalPoints: number;
  checkoutCart = new CheckoutCart();
  cartSubscription: Subscription;
  pointsSubscription: Subscription;
  store = environment.name;
  isLoggedIn: boolean

  constructor(private dialogRef: MatDialogRef<CartComponent>,
              private checkoutService: CheckoutService,
              private relationService: RelationService,
              private installmentService: InstallmentService,
              private _authenticationService: AuthenticationService,
              private router: Router,
              private pointsService: PointsService,
              private renderer: Renderer2,
              private element: ElementRef) { }

  ngOnInit() {
    this.cartSubscription = this.checkoutService.cartUpdated.subscribe(
      (value: boolean) => {
        this.checkoutCart = this.checkoutService.getCart();
        this.installmentsCheck();
      },
    );

    this.pointsSubscription = this.pointsService.status.subscribe(
      (response) => {
        this.points = this.pointsService.getPoints();
        this.subTotalPoints = this.pointsService.subTotal(this.checkoutCart.checkout_items);
      }
    );
    this.fireCart();

    this.isLoggedIn = this._authenticationService.isLoggedIn();
  }

  fireCart() {
    this.checkoutService.init().pipe( finalize(() => { this.loaded = true; }) ).subscribe(
      (response) => {
        this.checkoutCart = this.relationService.parse(response, CheckoutCart);
        this.checkoutService.setCart(this.checkoutCart);
        this.points = this.pointsService.getPoints();
        this.subTotalPoints = this.pointsService.subTotal(this.checkoutCart.checkout_items);
      },
      (error) => {
        this.checkoutService.destroyCart();
        this.checkoutCart = new CheckoutCart();
      }
    );
  }

  unevenInstallments(){
    let installments: number[];
    this.checkoutCart.checkout_items.forEach(item => {
      item.product.promotions.forEach(promotion =>{
        promotion.brands.forEach(brand => {
          brand.installments.forEach(installment => {
            installments.push(installment.installments);
          });
        });
      });
    });
  }

  ngOnDestroy() {
    if(this.cartSubscription) this.cartSubscription.unsubscribe();
    if(this.pointsSubscription) this.pointsSubscription.unsubscribe();
  }

  removeVariant(item) {
    this.checkoutService.removeVariant(item.variant.id);
    this.pointsService.updatePoints(item.points);
    this.renderer.removeChild(
      this.element.nativeElement,
      this.element.nativeElement.querySelector('.item-variant-' + item.variant.id)
    );
    this.installmentsCheck();
    this.fireCart();
  }

  closeDialog() {
    this.router.navigate(['/home']);
    this.dialogRef.close();
  }

  goToCheckout() {
    this.dialogRef.close();
    let route = ''

    if( environment.name !== 'BancoNacion'){
      route = '/checkout'

    }else if(environment.name === 'BancoNacion' && this.isLoggedIn){
      route = '/checkout'

    }else{
      route = '/login'
    }

    this.router.navigate([route]);
  }

  isObject(possibleObject) {
    return lodash.isObject(possibleObject);
  }

  floatParser(number: number){
    return PriceService.humanize(number);
  }

  inCreditAmount(item) {
    return this.pointsService.inCreditAmount(item);
  }

  installmentsCheck() {
    if (this.checkoutCart.total !== 0) {
      if(this.store =="Macro") this.installmentsError = this.installmentService.maxInstallment(this.checkoutCart)
      if(this.store !="Macro") this.installmentsError = true
    }
  }

  closeInstallmentMessege(){
    this.installmentsError = false;
  }

}
