import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { Metatag } from '../../../../models/models.model';
import { Router } from '@angular/router';
import { QualtricsService } from '../../../../services/third-party/qualtrics.service';

declare var gtag:any;

export interface Service{
  name: string,
  url: string
}

@Injectable({
  providedIn: 'root'
})
export class MktService {

  private dictionary = [
    'LOCATION' , // street, number, city, province, country, zip_code, lat, lng |
    'LOCATION-ERROR', // latitude, longitude |
    'ANONIMOUS-USER',
    'LOGIN',
    'REGISTER-NEW-USER',
    'RECOVERY-PASSWORD',
    'VIEW', // path |
    'VIEW-PHARMA', // name |
    'PRODUCT-DETAIL', // name, ean, productId, variantId |
    'SEARCH', // search |
    'ADD-TO-CART', // cart, item, quantity, priceUnit |
    'REMOVE-TO-CART', // cart, item, quantity |
    'DELETE-CART', // cart |
    'CHECKOUT', // label, step |
    'GENERATED-ORDER', // id, shop |
    'GO-TO-MERCADOPAGO', // url |
    'PAYMENT-SUCCESS', // orderResponse |
    'PAYMENT-DECLINED', // orderResponse |
    'ORDER-CANCELED', // url |
    'CLICK-IN-BANNER', //item
    'CLICK-IN-LOGO',  //
    'CLICK-IN-OFFER', // url
    'RECARGA-SUBE', // recarga_sube_data
    'INTANGIBLE-PRODUCTS', // product_name | data
    'RECARGA-CELULARES', //recarga_celulares_data
    'MILLAS-AEROLINEAS-PLUS', // millas_aerolineas_data
    'SEND',
    'Window Loaded',
    'Timer',
    'Scroll Depth',
    'Form Submission',
    'Element Visibility',
    'YouTube Video',
    'All Elements'
  ]
  private nameShop = environment.name;
  private isAvailable = environment.production;

  constructor(
    private _titleService: Title,
    private _metaService: Meta,
    private _router: Router,
    private _qualtricsService: QualtricsService
  ) {
    this.setConfig()
  }

  setMetaTags(title: string | null = null, metadata: Metatag | any = null) {

    if (title) this._titleService.setTitle(title);

    if (metadata) {

      let metas = [
        { name: 'og:title', content: metadata.title },
        { name: 'twitter:title', content: metadata.title },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
      if (metadata.description) {
        let moreMetas = [
          { name: 'description', content: metadata.description },
          { name: 'abstract', content: metadata.description },
          { name: 'og:description', content: metadata.description }
        ]
        moreMetas.map(m => metas.push(m));
      }
      if (environment.name) {
        let moreMetas = [
          { name: 'copyright', content: environment.name },
          { name: 'og:site_name', content: environment.name }
        ]
        moreMetas.map(m => metas.push(m));
      }
      if (environment.domain) {
        const image_src = metadata.image_src || environment.domain + '/assets/stores/current_store/images/bar-store-logo.svg';

        let moreMetas: any = [
          { name: 'twitter:url', content: environment.domain + this._router.url },
          { name: 'og:url', content: environment.domain + this._router.url },
          { name: 'og:image', content: image_src },
          { property: 'twitter:image', content: image_src }
        ]
        moreMetas.map(m => metas.push(m));
      }
      if (environment.twitter) {
        let moreMetas: any = [
          { name: 'twitter:site', content: environment.twitter },
          { name: 'twitter:creator', content: environment.twitter }
        ]
        moreMetas.map(m => metas.push(m));
      }
      metas.map(m => this._metaService.updateTag(m));

      this._metaService.addTags([
        { name: 'og:country-name', content: 'Argentina' },
        { name: 'og:type', content: 'article' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0,minimum-scale=1.0,shrink-to-fit=no' },
        { name: 'rating', content: 'global' },
        { name: 'expires', content: 'never' },
        { name: 'distribution', content: 'global' },
        { name: 'robots', content: 'index' },
        { name: 'twitter:description', content: 'Argentina' }
      ]);
    }
  }

  collect(event: string, tags: any = {}) {
    if (this.dictionary.includes(event) && this.isAvailable) {
      let sign = { 'send_to': this.nameShop };
      const returnedTarget = Object.assign(sign, tags);
      gtag('event', event, returnedTarget);
    }
  }

  setConfig() {
    const script = document.createElement('script');
    script.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){ dataLayer.push(arguments);}
      gtag('js', new Date());
      `;
    document.head.prepend(script);

    if (this.isAvailable) {
      if (environment.gtm_id.length > 0) environment.gtm_id.map(gtm_id => this.setGTM(gtm_id));
      if (environment.zoho_url !== '') this.setZoho(environment.zoho_url);
      if (environment.emblue_code !== '') this.setEmblue(environment.emblue_code);
      if (environment.zendesk_key !== '') this.setZendesk(environment.zendesk_key);
      if(environment.name === 'Macro') {
        this.isAvailable ? this.setSalesforceScript() : this.setSalesforceScriptDev();
        if  (environment.facebook_pixel_id !== '') this.setFacebookScript(environment.facebook_pixel_id);
      }
    }

    if(environment.name === 'Macro' || environment.name === 'BancoNacion' || environment.name === 'BancoCiudad') this.setModo();
    if(environment.name === 'Macro') this._qualtricsService.setQualtricsScriptTag()
  }

  setGTM(gtm_id: string) {
    gtag('config', gtm_id, { 'groups': this.nameShop });

    const script = document.createElement('script');
    script.async = true;
    script.src = (/^GTM.*$/.test(gtm_id)? 'https://www.googletagmanager.com/gtm.js?id=':'https://www.googletagmanager.com/gtag/js?id=') + gtm_id;
    document.head.prepend(script);

    const script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.text = `
    window['dataLayer'] = window['dataLayer']||[];
    window['dataLayer'].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
    `;
    document.head.prepend(script2);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = "https://www.googletagmanager.com/ns.html?id=" + gtm_id;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";
    noscript.append(iframe);
    document.body.prepend(noscript);
  }

  setZoho(zoho_url: string) {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = zoho_url;
    document.head.prepend(script);
  }

  setEmblue(key:string) {
    const script = document.createElement('script')
    script.src = `https://cdn.embluemail.com/pixeltracking/pixeltracking.js?code=${key}`
    document.head.append(script)
  }


  setModo() {
    const script = document.createElement('script');
    script.src = this.isAvailable ? 'https://ecommerce-modal.modo.com.ar/bundle.js' : 'https://ecommerce-modal.preprod.modo.com.ar/bundle.js';
    document.head.prepend(script);
  }

  setZendesk(zendesk_key) {
    const script = document.createElement('script');
    script.id = 'ze-snippet';
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=' + zendesk_key;
    document.head.append(script);
  }

  setFacebookScript( id: string){
    const script = document.createElement('script');
    const meta = document.createElement('meta');
    script.async = true;
    script.type = 'text/javascript'
    script.text = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', "${id}");
    fbq('track', 'PageView');`
    meta.name = 'facebook-domain-verification'
    meta.content = id;
    document.head.prepend(script, meta);
  }

  setSalesforceScript(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.evgnet.com/beacon/bancomacrosa/prod/scripts/evergage.min.js';
    document.head.prepend(script);
  }

  setSalesforceScriptDev(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.evgnet.com/beacon/bancomacrosa/prod/scripts/evergage.min.js';
    document.head.prepend(script);
  }
}
