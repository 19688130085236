<div class="accordion">
  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-1">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-1"
          aria-expanded="false"
          aria-controls="collapse-1">
          {{ '¿Qué es BNA móvil?' | uppercase }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-1" class="collapse" aria-labelledby="group-1">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  BNA móvil es una plataforma digital que te brindará la
                  posibilidad de adquirir uno de los vehículos publicados en la
                  Tienda BNA+, financiado con un préstamo del Banco Nación
                  (BNA).
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-2">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-2"
          aria-expanded="false"
          aria-controls="collapse-2">
          {{
            '¿Qué productos puedo comprar con el préstamo del BNA?' | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-2" class="collapse" aria-labelledby="group-2">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podrás elegir cualquiera de los vehículos 0km publicados en
                  Tienda BNA+/BNA móvil.
                  <br />
                  Entre los productos ofrecidos podrás encontrar, motocicletas
                  convencionales y vehículos eléctricos como motos, bicicletas y
                  monopatines.
                  <br />
                  También podrán incluirse accesorios (como indumentaria,
                  cascos, etc), que se publiquen en conjunto con el vehículo a
                  financiar (venta en combo).
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-3">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-3"
          aria-expanded="false"
          aria-controls="collapse-3">
          {{ '¿Quiénes pueden solicitar el préstamo personal?' | uppercase }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-3" class="collapse" aria-labelledby="group-3">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  El préstamo está orientado para todas las personas humanas
                  aptas para obligarse crediticiamente.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-4">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-4"
          aria-expanded="false"
          aria-controls="collapse-4">
          {{
            '¿Puedo acceder al préstamo personal si no soy cliente del BNA?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-4" class="collapse" aria-labelledby="group-4">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Sí, podrás solicitarlo igualmente.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-5">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-5"
          aria-expanded="false"
          aria-controls="collapse-5">
          {{
            '¿Puedo acceder al Préstamo Personal si soy jubilado o estoy próximo a jubilarme?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-5" class="collapse" aria-labelledby="group-5">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Sí, podrás solicitar el préstamo para adquirir los vehículos
                  0Km.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-6">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-6"
          aria-expanded="false"
          aria-controls="collapse-6">
          {{ '¿Dónde puedo ver las condiciones del préstamo?' | uppercase }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-6" class="collapse" aria-labelledby="group-6">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podrás informarte y conocer las características y condiciones
                  del préstamo en
                  <a
                    href="https://www.bna.com.ar/Personas/NacionMovil"
                    target="_blank"
                    >https://www.bna.com.ar/Personas/NacionMovil</a
                  >
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-7">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-7"
          aria-expanded="false"
          aria-controls="collapse-7">
          {{
            '¿Cómo me compro la moto u otro vehículo con un préstamo del BNA?'
              | uppercase
          }}
          {{ 'Siguiendo estos simples pasos:' | uppercase }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-7" class="collapse" aria-labelledby="group-7">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  1- Ingresá a Tienda BNA+/BNA móvil y seleccioná el vehículo de
                  tu preferencia.
                  <br />
                  <br />
                  2- Completá tus datos y conocé sí tenés una oferta de préstamo
                  disponible.
                  <br />
                  <br />
                  3- Concretá la reserva del vehículo en Tienda BNA+ y
                  presentate en la sucursal del BNA que elegiste.
                  <br />
                  <br />
                  4- La empresa vendedora te contactará para cerrar la operación
                  y coordinar la entrega del vehículo.
                  <br />
                  <br />
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-8">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-8"
          aria-expanded="false"
          aria-controls="collapse-8">
          {{
            '¿Puedo comprar cualquiera de los vehículos que se encuentran publicados en BNA móvil?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-8" class="collapse" aria-labelledby="group-8">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Sí. Recordá que para la compra es condición que el BNA te
                  otorgue un préstamo.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-9">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-9"
          aria-expanded="false"
          aria-controls="collapse-9">
          {{
            '¿Cómo puedo pagar el vehículo que elegí con el préstamo que me otorgue el Banco?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-9" class="collapse" aria-labelledby="group-9">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podrás seleccionar el monto del préstamo que quieras utilizar,
                  sin superar el máximo de la oferta del Banco, y con él podrás
                  abonar el vehículo en forma total o parcial. En caso que el
                  precio del vehículo sea mayor al del préstamo otorgado, podrás
                  abonar la diferencia con otro medio de pago a coordinar con la
                  empresa vendedora.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-10">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-10"
          aria-expanded="false"
          aria-controls="collapse-10">
          {{
            '¿Pueden existir gastos adicionales al valor publicado del vehículo?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-10" class="collapse" aria-labelledby="group-10">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Sí, la empresa vendedora puede cobrar gastos adicionales.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-11">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-11"
          aria-expanded="false"
          aria-controls="collapse-11">
          {{
            '¿Puedo financiar los gastos adicionales con el préstamo personal?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-11" class="collapse" aria-labelledby="group-11">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  No. El pago de estos deberás coordinarlos con la empresa
                  vendedora.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-12">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-12"
          aria-expanded="false"
          aria-controls="collapse-12">
          {{
            '¿Cómo puedo conocer el monto de los gastos adicionales al valor publicado del vehículo?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-12" class="collapse" aria-labelledby="group-12">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Para ello podrás consultarle a la empresa vendedora.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-13">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-13"
          aria-expanded="false"
          aria-controls="collapse-13">
          {{
            '¿Existe algún tope máximo que la empresa vendedora pueda cobrar en concepto de gastos por encima del precio publicado?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-13" class="collapse" aria-labelledby="group-13">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Los gastos adicionales no podrán superar los siguientes
                  valores(*), los cuales
                  <span style="font-weight: 800; text-decoration: underline"
                    >no incluyen los definidos por la Dirección Nacional del
                    Registro de la Propiedad Automotor para la inscripción del
                    vehículo:</span
                  >
                  <br />
                  <br />
                  <table
                    style="
                      width: 100%;
                      border-collapse: collapse;
                      text-align: center;
                    "
                    border="1">
                    <thead>
                      <tr>
                        <th>Segmento del vehículo</th>
                        <th>Valor en $ del vehículo</th>
                        <th>Gastos Máximos sobre el valor del vehículo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Chico</td>
                        <td>de 0 a 3.999.999</td>
                        <td>7,5%</td>
                      </tr>
                      <tr>
                        <td>Mediano</td>
                        <td>de 4.000.000 a 9.999.999</td>
                        <td>6,0%</td>
                      </tr>
                      <tr>
                        <td>Grande</td>
                        <td>Más de 10.000.000</td>
                        <td>5,7%</td>
                      </tr>
                    </tbody>
                  </table>
                  <br />
                  <p style="font-size: 12px; text-align: left"
                    >(*) Los presentes porcentajes podrán sufrir futuras
                    adecuaciones.</p
                  >
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-14">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-14"
          aria-expanded="false"
          aria-controls="collapse-14">
          {{
            'Recibí un correo electrónico con la oferta del préstamo para reservar un vehículo en la Tienda, pero no puedo realizar la reserva ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-14" class="collapse" aria-labelledby="group-14">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  En caso de que no puedas generar la reserva, te sugerimos que
                  te dirijas inmediatamente a la sucursal del banco que elegiste
                  para que podamos ayudarte.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-15">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-15"
          aria-expanded="false"
          aria-controls="collapse-15">
          {{
            'No puedo contactarme con la empresa vendedora, ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-15" class="collapse" aria-labelledby="group-15">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Si tuviste inconvenientes para contactarte con el
                  concesionario, te invitamos a completar el siguiente
                  formulario para que podamos ayudarte.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-16">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-16"
          aria-expanded="false"
          aria-controls="collapse-16">
          {{
            'Hice la solicitud de préstamo y me informaron que tengo una oferta disponible, pero aún no generé la reserva de un vehículo en la Tienda ¿Puedo arrepentirme? ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-16" class="collapse" aria-labelledby="group-16">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podés arrepentirte. No es necesario que hagas nada. Tu
                  solicitud se dará por desistida.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-17">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-17"
          aria-expanded="false"
          aria-controls="collapse-17">
          {{
            'Me informaron que tengo una oferta de préstamo disponible y ya generé la reserva del vehículo en la Tienda. ¿Puedo arrepentirme? ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-17" class="collapse" aria-labelledby="group-17">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podrás arrepentirte. Deberás efectuar el aviso en la sucursal
                  del BNA que elegiste.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-18">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-18"
          aria-expanded="false"
          aria-controls="collapse-18">
          {{
            'Me informaron que el Banco aprobó mi préstamo, pero aún no desembolsó el dinero para la compra del vehículo. ¿Puedo arrepentirme? ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-18" class="collapse" aria-labelledby="group-18">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Podrás arrepentirte. Deberás efectuar el aviso en la sucursal
                  del BNA que elegiste.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-19">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-19"
          aria-expanded="false"
          aria-controls="collapse-19">
          {{
            'El Banco ya desembolsó el dinero para la compra del vehículo. ¿Puedo arrepentirme? ¿Qué debo hacer?'
              | uppercase
          }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-19" class="collapse" aria-labelledby="group-19">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Deberás efectuar el aviso en la sucursal del Banco que
                  elegiste. Allí te informarán las condiciones que aplican en tu
                  caso.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div class="card">
    <div class="card-header p-0" id="group-20">
      <h2 class="mb-0">
        <button
          style="font-size: 12px"
          class="btn btn-link btn-block text-left text-dark"
          type="button"
          data-toggle="collapse"
          data-target="#collapse-20"
          aria-expanded="false"
          aria-controls="collapse-20">
          {{ '¿Cuánto demora la entrega del vehículo?' | uppercase }}
          <i
            class="fa fa-chevron-down"
            style="opacity: 0.2; float: right; margin-top: 4px"></i>
        </button>
      </h2>
    </div>

    <div id="collapse-20" class="collapse" aria-labelledby="group-20">
      <div class="card-body">
        <p style="margin-left: 0cm; margin-right: 0cm; text-align: justify">
          <span style="font-size: 12px">
            <span style="background-color: white">
              <span style="font-family: Tahoma, Geneva, sans-serif">
                <span style="color: #333333">
                  Los tiempos de entrega dependerán de lo que acuerdes con la
                  empresa vendedora.
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  </div>
  <!-- End Section -->
</div>
