<ng-container *ngIf="STORE_NAME.MACRO === storeName">

  <ng-container *ngIf="!isLoading && !isFinished">
    <div class="wrapper justify-content-center align-items-end position-relative">
      <div class="bg-wrapper position-absolute w-100 h-100 d-flex justify-content-center align-items-start px-2">
        <img src="assets/stores/macro/images/countdown/bg-countdown.svg"
             alt="background cibery monday"
        >
      </div>
      <p class="text-count">LLEGA EN</p>
      <div class="d-flex flex-row countdown justify-content-center ">
        <div class="d-flex flex-column unit-wrapper  align-items-center">
          <span class="unit">{{ timeRemaining.days.toString().padStart(2, '00') }}</span>
          <span class="unit-name">DÍAS</span>
        </div>
        <span class="separators">:</span>
        <div class="d-flex flex-column unit-wrapper  align-items-center">
          <span class="unit">{{ timeRemaining.hours.toString().padStart(2, '00') }}</span>
          <span class="unit-name">HORAS</span>
        </div>
        <span class="separators">:</span>
        <div class="d-flex flex-column unit-wrapper  align-items-center">
          <span class="unit">{{ timeRemaining.minutes.toString().padStart(2, '00') }}</span>
          <span class="unit-name">MINUTOS</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
