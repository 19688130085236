import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

import { GtmService } from './gtm.service';
import { Brand } from '../models/models.model';
import { Catalog } from '../models/models.model';
import { CheckoutCart } from '../models/models.model';
import { CheckoutItem } from '../models/models.model';
import { environment } from 'environments/environment';
import { Product } from '../models/models.model';
import { Variant } from '../models/models.model';
import { Landing } from "../models/models.model";

@Injectable()
export class GtmFbService {
  currency_code: string;
  products: object;
  maxProducts: number;

  constructor(private _sanitizer: DomSanitizer,
              private gtmService: GtmService) {}

  // VIEW_CONTENT
  public builderPageProduct(product: Product) {
    const data = {
      'event': 'ViewContent',
      'value': product.onSalePrice(),
      'currency': 'ARS',
      'content_ids': product.id,
      'content_type': 'product',
      'product_name': product.title
    };
    this.push(data);
  }

  public builderPageCheckout(checkoutCart: CheckoutCart) {
    const data = {
      'event': 'InitiateCheckout',
      'value': checkoutCart.total,
      'currency': 'ARS',
      'content_name': checkoutCart.title,
      'content_category': 'null',
      'content_type': 'product_group',
      'content_ids': checkoutCart.purchase_id,
      'num_items': checkoutCart.checkout_items.length
    };
    this.push(data);
  }

  public builderAddCart(product: Product, variant: Variant, quantity) {
    const data = {
      'event': 'AddToCart',
      'value': product.onSalePrice(),
      'currency': 'ARS',
      'content_ids': product.id,
      'content_type': 'product'
    };
    this.push(data);
  }

  public builderPurchase(checkoutCart: CheckoutCart) {
    if (!checkoutCart.checkout_items) {
      return false;
    }
    const products = checkoutCart.checkout_items.map((checkout_item, index) => {
      return this.hashCheckout(checkout_item, checkout_item.product);
    });
    const data = {
      'event': 'Purchase',
      'value': checkoutCart.total,
      'currency': 'ARS',
      'content_ids': checkoutCart.purchase_id,
      'content_category': 'null',
      'content_type': 'product_group',
      'contents': products,
      'num_items': checkoutCart.checkout_items.length
    };    this.push(data);
  }

  builderLead(){
    // Cliente potencial
    const data = {
      'event': 'Lead',
    };
    this.push(data);
  }

  private hashCheckout(checkoutItem: CheckoutItem, product: Product) {
    return {
      'id': product.id.toString(),
      'name': this.sanitize_string( product.title ),
      'brand': this.sanitize_string(product.manufacturer.name),
      'category': this.sanitize_string(product.category.name),
      'item_price': checkoutItem.realPrice(),
      'variant': this.setValue(checkoutItem.variant.name),
      'sku': checkoutItem.variant.gp_sku,
      'quantity': checkoutItem.quantity
     };
  }

  private push(data) {
    this.gtmService.push(data);
  }

  private sanitize_string(string: string) {
    if (string === 'undefined') {
      return this.setValue(string);
    }

    return this._sanitizer.sanitize(SecurityContext.HTML, string);
  }

  private setValue(value) {
    return typeof value === 'undefined' ? '' : value;
  };
}
