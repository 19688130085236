import {Injectable} from "@angular/core";

@Injectable()
export class PriceService {

  static humanize(number: number) {
    if (number === undefined || number === null) {
      return number;
    }

    let parse_number = Number(number).toLocaleString('de-DE').replace(",", ".");
    if ( number % 1 !== 0 ) {
      const parse_float = Number(number).toFixed(2).toLocaleString().replace(",", ".");
      const index = parse_number.lastIndexOf('.');
      if ( (parse_number.match(/[.]/g) || []).length > 1) {
        parse_number = parse_number.substr(0, index) + ',' + parse_float.substr(index);
      } else {
        parse_number = parse_float.substr(0, index) + ',' + parse_float.substr(index + 1);
      }
    }
    return parse_number;
  }

  static humanizeWithoutDecimals(number: number) {
    if (number === undefined || number === null) {
      return number;
    }
  
    let parse_number = Math.round(Number(number)).toLocaleString('de-DE').replace(",", ".");
  
    return parse_number;
  }
}
