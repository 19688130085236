import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { GtmService } from './gtm.service';
import {
  Brand,
  Catalog,
  CheckoutCart,
  CheckoutItem,
  Product,
  Variant
} from '../models/models.model';
import { environment } from '../environments/environment';

@Injectable()
export class GtmBuilderService {
  currency_code: string;
  products: object;
  maxProducts: number;

  constructor(private _sanitizer: DomSanitizer,
              private gtmService: GtmService) {
    this.maxProducts = 25;
  }

  public builderHome(component: any) {
    const variants = component.data.variants;
    if (!variants.length) return
    // console.log('builderHome');
    const slug = 'landing-featured-products-' + component.id;
    this.currency_code = variants[0]?.product.currencyCode ?? 'ARS';
    this.builderProducts(variants, slug);
  }

  public builderPageCatalog(catalog: Catalog, slug: string) {
    // console.log('builderPageCatalog');
    slug = slug + '-catalog-' + catalog.pagination.current_page;
    this.currency_code = catalog.variants[0].product.currency_code;
    this.builderProducts(catalog.variants, slug, catalog.title);
  }

  public builderPageBrand(brand: Brand, slug: string) {
    // console.log('builderPageBrand');
    slug = slug + '-brand-';
    this.currency_code = brand.variants[0].product.currency_code;
    this.builderProducts(brand.variants, slug, brand.full_name);
  }

  public builderPageProduct(product: Product) {
    this.push(this.hashProductDetail(product));
  }

  builderGA4BeginCheckout(checkoutCart: CheckoutCart) {
    const productsBeginCheckout = checkoutCart.checkout_items.map((checkout_item, index) => {
      return this.hashBeginCheckout(checkout_item, checkout_item.product);
    });

    const dataBeginCheckout = {
      'ecommerce': {
        'currency': 'ARS',
        'value': checkoutCart?.total,
        'coupon': checkoutCart?.coupon,
        'items': productsBeginCheckout,
      },
      'event': 'begin_checkout'
      };
      this.push(dataBeginCheckout);
  }

  builderGA4AddShippingInfo(checkoutCart: CheckoutCart) {
    const productsAddShippingInfo = checkoutCart.checkout_items.map((checkout_item, index) => {
      return this.hashBeginCheckout(checkout_item, checkout_item.product);
    });

    const dataAddShippingInfo = {
      'ecommerce': {
        'currency': 'ARS',
        'value': checkoutCart?.total,
        'coupon': checkoutCart?.coupon,
        'items': productsAddShippingInfo,
      },
      'event': 'add_shipping_info'
      };
      this.push(dataAddShippingInfo);
  }

  builderGA4AddPaymentInfo(checkoutCart: CheckoutCart, env: string, stepper: number) {
    let productsAddPaymentInfo = {};
    if (checkoutCart && env === 'Macro' && stepper === 1) {
      productsAddPaymentInfo = checkoutCart.checkout_items.map((checkout_item, index) => {
        return this.hashBeginCheckout(checkout_item, checkout_item.product);
      });
    }

    const dataPaymentInfo = {
      'ecommerce': {
        'currency': 'ARS',
        'value': checkoutCart?.total,
        'coupon': checkoutCart?.coupon,
        'items': productsAddPaymentInfo,
      },
      'event': 'add_payment_info'
      };
      this.push(dataPaymentInfo);
  }

  public builderPageCheckout(step, option, checkoutCart: CheckoutCart) {
    const products = checkoutCart.checkout_items.map((checkout_item, index) => {
      return this.hashCheckout(checkout_item, checkout_item.product);
    });

    const data = {
      'ecommerce': {
        'checkout': {
          'actionField': {'step': step, 'option': 'checkout'},
          'products': products
        }
      },
      'event': 'checkout'
    };

    this.push(data);
    const checkoutData = {
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': step, 'option': option }
        }
      },
      'event': 'checkoutOption'
    };

    this.push(checkoutData);
  }

  public builderProducts(variants: any, slug?: string, title?: string) {
    const products = variants.map((variant, index) => {
      return this.hashProduct(slug, variant, index, title);
    });

    this.hashEcProducts(products);
  }

  public builderAddCart(product: Product, variant: Variant, quantity) {
    const data = {
      'ecommerce': {
        'currencyCode': 'ARS',
        'add': this.hashCartProduct(product, variant, quantity),
        'value': product.onSalePrice(),
      },
      'event': 'addToCart'
    };

    this.push(data);

    const dataAddToCart = {
      'ecommerce': {
        'currency': 'ARS',
        'value': product.onSalePrice(),
        'items': this.hashCartProductTagMacro(product, variant, quantity),
      },
      'event': 'add_to_cart'
    };

    this.push(dataAddToCart);
  }

  public builderRemoveCart(checkoutItem: CheckoutItem) {
    const data = {
      'ecommerce': {
        'currencyCode': 'ARS',
        'remove': this.hashCartProduct(checkoutItem.product, checkoutItem.variant, checkoutItem.quantity)
      },
      'event': 'removeFromCart'
    };

    this.push(data);
  }

  public builderProductClick(variant: Variant, slug: string, position: number) {
    const data = {
      'ecommerce': {
        'currencyCode': 'ARS',
        'click': {
           'actionField': {'list': slug},
           'products': [ this.hashProductClick(variant, slug, position) ]
        }
      },
      'event': 'productClick'
    };

    const dataSelectItem = {
      'ecommerce': {
        'item_list_id': variant?.id,
        'item_list_name': this.sanitize_string(variant?.product?.title),
        'currencyCode': 'ARS',
        'items': this.hashProductClickMacro(variant, slug, position),
      },
      'event': 'select_item'
    };

    this.push(data);
    this.push(dataSelectItem);
  }

  public builderPurchase(checkoutCart: CheckoutCart) {
    if (!checkoutCart.checkout_items) {
      return false;
    }
    const products = checkoutCart.checkout_items.map((checkout_item, index) => {
      return this.hashCheckout(checkout_item, checkout_item.product);
    });
    const data = {
      'ecommerce': {
        'purchase': {
          'actionField': this.hashInfoPurchase(checkoutCart),
          'products': products
        }
      },
      'event': 'purchase'
    };
    this.push(data);
  }

  builderMpClickBanner(banner) {
    const data = {
      event: "mp_click_banner",
    }
    this.push(data)
  }

  builderSelectPromotion(item) {
    const data = {
      event: "select_promotion",
    }
    this.push(data)
  }

  builderAddToWishlistMacro(variant: Variant) {
    // console.log({variant});
    const { product: { slug, currency_code, is_on_sale, sale_price, regular_price } } = variant;
    const data = {
      event: "add_to_wishlist",
      ecommerce: {
        currency: currency_code ?? "ARS",
        value: is_on_sale ? sale_price : regular_price,
        items: this.hashProductClickMacro(variant, slug, 0)
      }
    };
    this.push(data);
  }

  builderPurchaseMacro(checkoutCart: CheckoutCart) {
    if(!checkoutCart) return
    const { total, purchase_id, checkout_items } = checkoutCart;
    const [checkout_item] = checkout_items ?? [];
    const { product, variant } = checkout_item ?? {};

    // console.log({ purchase_id,checkoutCart, product, variant });

    const data = {
      event: "purchase",
      ecommerce: {
        transaction_id: purchase_id,
        value: total, // precio total?
        currency: product?.currency_code || "ARS",
        items: [
          {
            item_id: product?.variants[0]?.sku,
            item_name: this.sanitize_string(product?.title),
            item_brand: this.sanitize_string(product?.manufacturer?.name),
            item_category: this.sanitize_string(product?.category?.name),
            item_category5: (product?.is_on_sale === true) ? "On Sale" : "No",
            dimension6: this.sanitize_string(product?.shop["title"]),
            item_list_id: product?.id?.toString(),
            item_list_name: this.sanitize_string(product?.title),
            item_variant: this.setValue(variant?.gp_sku),
            price: product?.onSalePrice(),
            quantity: 1,
          }
        ]
      }
    };
    this.push(data);
  }

  builderPurchaseMacroMODO(checkoutCart: CheckoutCart) {
    if(!checkoutCart) return
    const { total, purchase_id, checkout_items } = checkoutCart;
    const [checkout_item] = checkout_items ?? [];
    const { product, variant } = checkout_item ?? {};

    const data = {
      client_id: this.gtmService.get_ga_clientid(),
      events: [{
        name: "purchase",
        params: {
          currency: product?.currency_code || "ARS",
          transaction_id: purchase_id,
          value: total,
          items: [
            {
              item_id: product?.variants[0]?.sku,
              item_name: this.sanitize_string(product?.title),
              item_brand: this.sanitize_string(product?.manufacturer?.name),
              item_category: this.sanitize_string(product?.category?.name),
              item_variant: this.setValue(variant?.gp_sku),
              price: product?.onSalePrice(),
              quantity: 1,
              dimension5: "No",
              dimension6: this.sanitize_string(product?.shop["title"]),
              tipo_pago:"MODO"
            }
          ]
        }
      }]
    }
    this.push(data);
  }

  buildMpSearchForKeyword(keyword: string){
    const data = {
      event: 'mp_busquedas_por_keyword',
      busqueda_keyword: keyword
    }
    this.push(data);
  }

  buildMpMenuPrincipal(option:string){
    const data = {
      event: 'mp_menu_principal',
      operacion_clickeada: option
    }
    this.push(data);
  }

  private hashInfoPurchase(checkoutCart: CheckoutCart) {
    const data = {
      'action': 'purchase',
      'id': checkoutCart.purchase_id.toString(),
      'affiliation': environment.name,
      'revenue': (checkoutCart.subtotal - checkoutCart.coupon_total_discount),
      'tax': String(checkoutCart.taxes_cost),
      'shipping': String(checkoutCart.shipping_cost),
    };

    if (checkoutCart.coupon) {
      data['coupon'] = checkoutCart.coupon
    }
    return data
  }

  private hashProductClick(variant: Variant, slug: string, position: number) {
    const product = <Product> variant.product;
    return {
      'id': variant.product.id.toString(),
      'name': this.sanitize_string( variant.product.title ),
      'variant': this.setValue(variant.name),
      'price': (product.is_on_sale) ? product.sale_price : product.regular_price,
      'brand': this.sanitize_string(variant.product.manufacturer.name),
      'category': slug,
      'position': (position + 1),
      'dimension2': variant.id,
      'dimension5': (product.is_on_sale === true) ? 'On Sale ': 'No',
      'dimension6': this.setValue(product.shop)
    };
  }

  private hashProductClickMacro(variant: Variant, slug: string, position: number) {
    const product = <Product> variant.product;
    return [{
      'item_id': variant.product.id.toString(),
      'item_name': this.sanitize_string( variant.product.title ),
      'item_variant': this.setValue(variant.name),
      'price': (product.is_on_sale) ? product.sale_price : product.regular_price,
      'item_brand': this.sanitize_string(variant.product.manufacturer.name),
      'item_category': slug,
      'position': (position + 1),
      'dimension2': variant.id,
      'dimension5': (product.is_on_sale === true) ? 'On Sale ': 'No',
      'dimension6': this.setValue(product.shop),
      'item_list_id': variant?.id,
      'item_list_name': this.sanitize_string(product.title),
      'quantity': variant?.quantity,
    }];
  }

  private hashProduct(listName: string, variant: Variant, _i: number, title: string) {
    const product = <Product> variant.product;

    return {
      'name':this.sanitize_string( variant.product.title ),
      'id': variant.product.id.toString(),
      'price': (product.is_on_sale) ? product.sale_price : product.regular_price,
      'brand': this.sanitize_string(variant.product.manufacturer.name),
      'category': this.sanitize_string(title),
      'variant': this.setValue(variant.name),
      'list': listName,
      'position': _i,
    };
  }

  private hashEcProducts(products: any) {
    // console.log({'hashEcProducts':products, 'maxProducts': this.maxProducts});
    let p: any;

    if (products.length > this.maxProducts) {
      while (products.length) {
        p = products.splice(0, this.maxProducts);
        this.push(this.hashImpressions(p));
      }
    } else {
      this.push(this.hashImpressions(products));
    }
  }

  private hashProductDetail(product: Product) {
    return {
      'ecommerce': {
        'detail': {
          'products': [{
            'name': this.sanitize_string(product.title),
            'id': product.id.toString(),
            'price': product.onSalePrice(),
            'brand': this.sanitize_string(product.manufacturer.name),
            'category': this.sanitize_string(product.category.name),
            'dimension5': (product.is_on_sale === true) ? 'On Sale' : 'No',
            'dimension6': this.sanitize_string(product.shop['title'])
          }]
        }
      }
    };
  }

  private hashCartProduct(product: Product, variant: Variant, quantity) {
    return {
      'products': [{
        'id': product.id.toString(),
        'name': this.sanitize_string(product.title),
        'category': this.sanitize_string(product.category.name),
        'brand': this.sanitize_string(product.manufacturer.name),
        'variant': this.setValue(variant.gp_sku),
        'price': product.onSalePrice(),
        'quantity': quantity,
        'dimension5': (product.is_on_sale === true) ? 'On Sale' : 'No',
        'dimension6': this.sanitize_string(product.shop['title'])
      }]
    }
  }

  private hashCartProductTagMacro(product: Product, variant: Variant, quantity?) {
    return [{
        'item_list_id': product.id.toString(),
        'item_list_name': this.sanitize_string(product.title),
        'item_id': product?.variants[0]?.sku,
        'item_name': this.sanitize_string(product.title),
        'item_category': this.sanitize_string(product.category.name),
        'item_brand': this.sanitize_string(product.manufacturer.name),
        'item_variant': this.setValue(variant.gp_sku),
        'price': product.onSalePrice(),
        'quantity': quantity,
        'dimension5': (product.is_on_sale === true) ? 'On Sale' : 'No',
        'dimension6': this.sanitize_string(product.shop['title'])
      }]
  }

  private hashCheckout(checkoutItem: CheckoutItem, product: Product) {
    return {
      'id': product.id.toString(),
      'name': this.sanitize_string( product.title ),
      'brand': this.sanitize_string(product.manufacturer.name),
      'category': this.sanitize_string(product.category.name),
      'price': checkoutItem.realPrice(),
      'variant': this.setValue(checkoutItem.variant.name),
      'sku': checkoutItem.variant.gp_sku,
      'quantity': checkoutItem.quantity,
      'dimension2': checkoutItem.variant.id,
      'dimension5': (checkoutItem.on_sale === true) ? 'On Sale ': 'No',
      'dimension6': this.sanitize_string(product.shop['title'])
     };
  }

  private hashBeginCheckout(checkoutItem: CheckoutItem, product: Product) {
    return {
      'id': product.id.toString(),
      'item_name': this.sanitize_string(product.title),
      'item_brand': this.sanitize_string(product.manufacturer.name),
      'item_category': this.sanitize_string(product.category.name),
      'price': checkoutItem.realPrice(),
      'item_variant': this.setValue(checkoutItem.variant.name),
      'item_id': checkoutItem.variant.gp_sku,
      'quantity': checkoutItem.quantity,
      'dimension2': checkoutItem.variant.id,
      'dimension5': (checkoutItem.on_sale === true) ? 'On Sale ': 'No',
      'dimension6': this.sanitize_string(product.shop['title'])
     };
  }

  private hashImpressions(products: any) {
    // console.log({ hashImpressions: products });
    return {
      'ecommerce': {
        'currencyCode': this.currency_code,
        'impressions': products
      }
    };
  }

  private push(data) {
    this.gtmService.push(data);
  }

  private sanitize_string(string: string) {
    if (string === 'undefined') {
      return this.setValue(string);
    }

    return this._sanitizer.sanitize(SecurityContext.HTML, string);
  }

  private setValue(value) {
    return typeof value === 'undefined' ? '' : value;
  };
}
